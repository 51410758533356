import { gql } from '@apollo/client';

export const GET_ASSOCIATIONS_FILTER = gql`
  query GET_ASSOCIATIONS_FILTER {
    associations {
      id
      name
      uuid
    }
  }
`;

export const GET_BATCHES_FILTER = gql`
  query GET_BATCHES_FILTER {
    batches {
      id
      name
      uuid
    }
  }
`;

export const GET_STAININGS_FILTER = gql`
  query GET_STAININGS_FILTER {
    stainings {
      id
      name
      uuid
    }
  }
`;

export const GET_TISSUES_FILTER = gql`
  query GET_TISSUES_FILTER {
    tissues {
      id
      name
      uuid
    }
  }
`;

export const GET_SCANNERS_FILTER = gql`
  query GET_SCANNERS_FILTER {
    scanners {
      id
      vendor
      model
      uuid
    }
  }
`;

export const GET_DISEASES_FILTER = gql`
  query GET_DISEASES_FILTER {
    diseases {
      name
    }
  }
`;

export const GET_SAMPLE_TYPES_FILTER = gql`
  query GET_SAMPLE_TYPES_FILTER {
    sampleTypes {
      name
    }
  }
`;

export const GET_SAMPLE_PREPARATIONS_FILTER = gql`
  query GET_SAMPLE_PREPARATIONS_FILTER {
    samplePreparations {
      name
    }
  }
`;

export const GET_PROJECTS_FILTER = gql`
  query GET_PROJECTS_FILTER {
    projects(isAdminView: true) {
      nodes {
        id
        name
      }
    }
  }
`;

export const GET_SUBPROJECTS_FILTER = gql`
  query GET_SUBPROJECTS_FILTER($projectId: ID!) {
    project(id: $projectId, isAdminView: true) {
      subProjects(isAdminView: true) {
        nodes {
          id
          name
        }
      }
    }
  }
`;

export const GET_SLIDES_QUERY = gql`
  query FETCH_WSIS(
    $page: Int
    $pageSize: Int
    $associations: [ID]!
    $batches: [ID]!
    $case: String
    $objectivePowers: [Float]!
    $scanners: [ID]!
    $stainings: [ID]!
    $tissues: [ID]!
    $diseases: [String]!
    $samplePreparation: [String]!
    $sampleType: [String]!
    $projects: [String]!
    $subProjects: [String]!
    $sortBy: SlideLibrarySortValues!
    $sortDirection: SlideLibrarySortDirection!
    $blockName: String
    $caseName: String
    $caseId: String
    $name: String
    $uuid: String
    $patientId: String
    $section: String
    $selectAll: Boolean
    $toggledNodes: [UUID]
  ) {
    slides(
      associations: $associations
      batches: $batches
      case: $case
      objectivePowers: $objectivePowers
      scanners: $scanners
      stainings: $stainings
      tissues: $tissues
      diseases: $diseases
      samplePreparation: $samplePreparation
      sampleType: $sampleType
      projects: $projects
      subprojects: $subProjects
      sortBy: $sortBy
      sortDirection: $sortDirection
      blockName: $blockName
      caseName: $caseName
      caseId: $caseId
      name: $name
      uuid: $uuid
      selectAll: $selectAll
      toggledNodes: $toggledNodes
      patientId: $patientId
      section: $section
      page: $page
      pageSize: $pageSize
    ) {
      nodes {
        id
        name
        originalName: name(type: original)
        batchName
        objectivePower
        tissue
        staining
        disease
        sampleType
        preparationType
        fluorescence {
          id
          name
          staining
        }
        case {
          id
          sid
          name
        }
        scanner {
          id
          vendor
          model
        }
        association {
          id
          name
        }
        blockName
        patientId
        section
        subprojectCount
        projectCount
      }
      pageInfo {
        page
        totalElements
        totalPages
      }
    }
  }
`;
