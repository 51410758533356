import { SortModelItem } from 'ag-grid-enterprise';
import { AppliedFilters } from '../types';

/**
 * Creates variables object for API calls by combining filters, pagination, sorting and selection
 * @param appliedFilters - Filter settings to apply to the data
 * @param page           - Current page number
 * @param pageSize       - Number of items per page
 * @param sortModel      - Model containing sorting configuration
 * @param selectionState - Optional object containing selection state (selectAll and toggledNodes)
 * @returns              Object containing all parameters needed for API call
 */
export const createVariables = (
  appliedFilters: AppliedFilters,
  page: number,
  pageSize: number,
  sortModel: SortModelItem[],
  selectionState?: {
    selectAll?: boolean;
    toggledNodes?: string[];
  }
): AppliedFilters & {
  page: number;
  pageSize: number;
  selectAll?: boolean;
  toggledNodes?: string[];
} => {
  const variables: AppliedFilters & {
    page: number;
    pageSize: number;
    selectAll?: boolean;
    toggledNodes?: string[];
    sortBy?: string;
    sortDirection?: string;
  } = {
    ...appliedFilters,
    page,
    pageSize,
    sortBy: sortModel?.[0]?.colId ? sortModel[0].colId : 'name',
    sortDirection: sortModel?.[0]?.sort || 'asc',
  };

  if (selectionState) {
    if (typeof selectionState.selectAll === 'boolean') {
      variables.selectAll = selectionState.selectAll;
    }
    if (selectionState.toggledNodes && selectionState.toggledNodes.length > 0) {
      variables.toggledNodes = selectionState.toggledNodes;
    }
  }

  return variables;
};
