import { PreparationType } from '@aignostics/onboarding-ui/src/types';
import { ApolloClient } from '@apollo/client';
import {
  ColDef,
  GridApi,
  SetFilterValuesFuncParams,
  ValueGetterParams,
} from 'ag-grid-enterprise';
import { MutableRefObject } from 'react';
import {
  Association,
  Batch,
  Disease,
  Project,
  SamplePreparation,
  SampleType,
  Scanner,
  Slide,
  Staining,
  Tissue,
} from '../../../types';
import SlideThumbnailLinkCell from '../components/SlideThumbnailLinkCell';
import {
  GET_ASSOCIATIONS_FILTER,
  GET_BATCHES_FILTER,
  GET_DISEASES_FILTER,
  GET_PROJECTS_FILTER,
  GET_SAMPLE_PREPARATIONS_FILTER,
  GET_SAMPLE_TYPES_FILTER,
  GET_SCANNERS_FILTER,
  GET_STAININGS_FILTER,
  GET_SUBPROJECTS_FILTER,
  GET_TISSUES_FILTER,
} from '../graphql/SlideLibrary.queries';

export interface FilterData {
  associations: Association[];
  batches: Batch[];
  diseases: Disease[];
  stainings: Staining[];
  samplePreparations: SamplePreparation[];
  sampleTypes: SampleType[];
  scanners: Scanner[];
  tissues: Tissue[];
  projects: Project[];
}

export const getSlideLibraryColumnDefs = (
  apolloClient: ApolloClient<object>,
  gridApi: MutableRefObject<GridApi<Slide> | null>
): ColDef<Slide>[] => {
  return [
    {
      colId: 'staining',
      headerName: 'Staining',
      field: 'staining',
      sortable: true,
      filter: 'agSetColumnFilter',
      valueGetter: (params: ValueGetterParams<Slide, Staining[]>) => {
        return params.node ? (params.node.data as Slide).staining : null;
      },
      filterParams: {
        values: async (params: SetFilterValuesFuncParams<Slide, Staining>) => {
          const { data } = await apolloClient.query({
            query: GET_STAININGS_FILTER,
            fetchPolicy: 'network-only',
          });

          if (!data?.stainings) {
            params.success([]);
            return;
          }

          params.success(data.stainings);
        },
        refreshValuesOnOpen: true,
        defaultToNothingSelected: true,
        valueFormatter: (params: { value: Staining }) => params.value.name,
        keyCreator: (params: { value: Staining }) => params.value.uuid,
        comparator: (a: Staining, b: Staining) => a.name.localeCompare(b.name),
      },
      minWidth: 140,
    },
    {
      colId: 'uuid',
      headerName: 'UUID',
      field: 'id',
      sortable: true,
      filter: 'agTextColumnFilter',
      filterParams: {
        filterOptions: ['contains'],
        maxNumConditions: 1,
      },
      minWidth: 200,
    },
    {
      colId: 'association',
      headerName: 'Association',
      field: 'association.name',
      sortable: true,
      filter: 'agSetColumnFilter',
      valueGetter: (params: ValueGetterParams<Slide, Association>) => {
        return params.node
          ? (params.node.data as Slide).association.name
          : null;
      },
      filterParams: {
        defaultToNothingSelected: true,
        refreshValuesOnOpen: true,
        values: async (
          params: SetFilterValuesFuncParams<Slide, Association>
        ) => {
          const { data } = await apolloClient.query({
            query: GET_ASSOCIATIONS_FILTER,
            fetchPolicy: 'network-only',
          });

          if (!data?.associations) {
            params.success([]);
            return;
          }

          params.success(data.associations);
        },
        valueFormatter: (params: { value: Association }) => params.value.name,
        keyCreator: (params: { value: Association }) => params.value.uuid,
        comparator: (a: Association, b: Association) =>
          a.name.localeCompare(b.name),
      },
      minWidth: 100,
    },
    {
      headerName: 'Batch',
      field: 'batchName',
      colId: 'batch',
      sortable: true,
      filter: 'agSetColumnFilter',
      valueGetter: (params: ValueGetterParams<Slide, Batch[]>) => {
        return params.node ? (params.node.data as Slide).batchName : null;
      },
      filterParams: {
        refreshValuesOnOpen: true,
        values: async (params: SetFilterValuesFuncParams<Slide, Batch>) => {
          const { data } = await apolloClient.query({
            query: GET_BATCHES_FILTER,
            fetchPolicy: 'network-only',
          });

          if (!data?.batches) {
            params.success([]);
            return;
          }

          params.success(data.batches);
        },
        defaultToNothingSelected: true,
        valueFormatter: (params: { value: Batch }) => params.value.name,
        keyCreator: (params: { value: Batch }) => params.value.uuid,
        comparator: (a: Batch, b: Batch) => a.name.localeCompare(b.name),
      },
      minWidth: 200,
    },
    {
      headerName: 'Localization',
      field: 'tissue',
      colId: 'tissue',
      sortable: true,
      filter: 'agSetColumnFilter',
      valueGetter: (params: ValueGetterParams<Slide, Tissue[]>) => {
        return params.node ? (params.node.data as Slide).tissue : null;
      },
      filterParams: {
        refreshValuesOnOpen: true,
        values: async (params: SetFilterValuesFuncParams<Slide, Tissue>) => {
          const { data } = await apolloClient.query({
            query: GET_TISSUES_FILTER,
            fetchPolicy: 'network-only',
          });

          if (!data?.tissues) {
            params.success([]);
            return;
          }

          params.success(data.tissues);
        },
        defaultToNothingSelected: true,
        valueFormatter: (params: { value: Tissue }) => params.value.name,
        keyCreator: (params: { value: Tissue }) => params.value.uuid,
        comparator: (a: Tissue, b: Tissue) => a.name.localeCompare(b.name),
      },
      minWidth: 140,
    },
    {
      colId: 'scanner',
      headerName: 'Scanner',
      field: 'scanner.vendor',
      sortable: true,
      filter: 'agSetColumnFilter',

      valueGetter: (params: ValueGetterParams<Slide, Scanner>) => {
        return params.node ? (params.node.data as Slide).scanner.vendor : null;
      },
      filterParams: {
        refreshValuesOnOpen: true,
        values: async (params: SetFilterValuesFuncParams<Slide, Scanner>) => {
          const { data } = await apolloClient.query({
            query: GET_SCANNERS_FILTER,
            fetchPolicy: 'network-only',
          });

          if (!data?.scanners) {
            params.success([]);
            return;
          }

          params.success(data.scanners);
        },
        defaultToNothingSelected: true,
        valueFormatter: (params: { value: Scanner }) =>
          `${params.value.vendor} ${params.value.model ?? ''}`,
        keyCreator: (params: { value: Scanner }) => params.value.uuid,
        comparator: (a: Scanner, b: Scanner) =>
          a.vendor.localeCompare(b.vendor),
      },
      minWidth: 140,
    },
    {
      colId: 'disease',
      headerName: 'Disease',
      field: 'disease',
      sortable: true,
      filter: 'agSetColumnFilter',
      valueGetter: (params: ValueGetterParams<Slide, Disease[]>) => {
        return params.node ? (params.node.data as Slide).disease : null;
      },
      filterParams: {
        refreshValuesOnOpen: true,
        values: async (params: SetFilterValuesFuncParams<Slide, Disease>) => {
          const { data } = await apolloClient.query({
            query: GET_DISEASES_FILTER,
            fetchPolicy: 'network-only',
          });

          if (!data?.diseases) {
            params.success([]);
            return;
          }

          params.success(data.diseases);
        },
        defaultToNothingSelected: true,
        valueFormatter: (params: { value: Disease }) => params.value.name,
        keyCreator: (params: { value: Disease }) => params.value.name,
        comparator: (a: Disease, b: Disease) => a.name.localeCompare(b.name),
      },
      hide: true,
      minWidth: 140,
    },
    {
      colId: 'objectivePower',
      headerName: 'Objective Power',
      field: 'objectivePower',
      sortable: true,
      filter: 'agSetColumnFilter',
      valueGetter: (params: ValueGetterParams<Slide, number[]>) => {
        return params.node ? (params.node.data as Slide).objectivePower : null;
      },
      filterParams: {
        values: [20, 40],
        defaultToNothingSelected: true,
        valueFormatter: (params: { value: number }) => String(params.value),
        keyCreator: (params: { value: number }) => params.value,
      },
      hide: true,
      minWidth: 100,
    },
    {
      colId: 'sampleType',
      headerName: 'Sample Type',
      field: 'sampleType',
      sortable: true,
      filter: 'agSetColumnFilter',
      valueGetter: (params: ValueGetterParams<Slide, SampleType[]>) => {
        return params.node ? (params.node.data as Slide).sampleType : null;
      },
      filterParams: {
        refreshValuesOnOpen: true,
        values: async (
          params: SetFilterValuesFuncParams<Slide, SampleType>
        ) => {
          const { data } = await apolloClient.query({
            query: GET_SAMPLE_TYPES_FILTER,
            fetchPolicy: 'network-only',
          });

          if (!data?.sampleTypes) {
            params.success([]);
            return;
          }

          params.success(data.sampleTypes);
        },
        defaultToNothingSelected: true,
        valueFormatter: (params: { value: SampleType }) => params.value.name,
        keyCreator: (params: { value: SampleType }) => params.value.name,
        comparator: (a: SampleType, b: SampleType) =>
          a.name.localeCompare(b.name),
      },
      hide: true,
      minWidth: 140,
    },
    {
      colId: 'preparationType',
      headerName: 'Preparation Type',
      field: 'preparationType',
      sortable: true,
      filter: 'agSetColumnFilter',
      valueGetter: (params: ValueGetterParams<Slide, PreparationType[]>) => {
        return params.node ? (params.node.data as Slide).preparationType : null;
      },
      filterParams: {
        refreshValuesOnOpen: true,
        values: async (
          params: SetFilterValuesFuncParams<Slide, PreparationType>
        ) => {
          const { data } = await apolloClient.query({
            query: GET_SAMPLE_PREPARATIONS_FILTER,
            fetchPolicy: 'network-only',
          });

          if (!data?.samplePreparations) {
            params.success([]);
            return;
          }

          params.success(data.samplePreparations);
        },
        defaultToNothingSelected: true,
        valueFormatter: (params: { value: PreparationType }) =>
          params.value.name,
        keyCreator: (params: { value: PreparationType }) => params.value.name,
        comparator: (a: PreparationType, b: PreparationType) =>
          a.name.localeCompare(b.name),
      },
      hide: true,
      minWidth: 140,
    },
    {
      colId: 'block',
      headerName: 'Block',
      field: 'blockName',
      sortable: true,
      valueGetter: (params: ValueGetterParams<Slide, string>) => {
        return params.node ? (params.node.data as Slide).blockName : null;
      },
      filter: 'agTextColumnFilter',
      filterParams: {
        filterOptions: ['contains'],
        maxNumConditions: 1,
      },
      hide: true,
      minWidth: 100,
    },
    {
      colId: 'caseID',
      headerName: 'Case UUID',
      field: 'case.id',
      sortable: true,
      filter: 'agTextColumnFilter',
      filterParams: {
        filterOptions: ['contains'],
        maxNumConditions: 1,
      },
      hide: true,
      minWidth: 100,
    },
    {
      colId: 'caseName',
      headerName: 'Case Name',
      field: 'case.name',
      filter: 'agTextColumnFilter',
      filterParams: {
        filterOptions: ['contains'],
        maxNumConditions: 1,
      },
      sortable: true,
      hide: true,
      minWidth: 140,
    },
    {
      colId: 'patientId',
      headerName: 'Patient',
      field: 'patientId',
      sortable: true,
      filter: 'agTextColumnFilter',
      filterParams: {
        filterOptions: ['contains'],
        maxNumConditions: 1,
      },
      hide: true,
      minWidth: 100,
    },
    {
      colId: 'section',
      headerName: 'Section',
      field: 'section',
      sortable: true,
      filter: 'agTextColumnFilter',
      filterParams: {
        filterOptions: ['contains'],
        maxNumConditions: 1,
      },
      hide: true,
      minWidth: 100,
    },
    {
      colId: 'projectCount',
      headerName: 'Project Count',
      field: 'projectCount',
      hide: true,
      sortable: false,
      filter: 'agSetColumnFilter',
      filterParams: {
        refreshValuesOnOpen: true,
        values: async (params: SetFilterValuesFuncParams<Slide, Project>) => {
          const { data } = await apolloClient.query({
            query: GET_PROJECTS_FILTER,
            fetchPolicy: 'network-only',
          });

          if (!data?.projects?.nodes) {
            params.success([]);
            return;
          }

          params.success(data.projects.nodes);
        },
        defaultToNothingSelected: true,
        valueFormatter: (params: { value: Project }) => params.value.name,
        keyCreator: (params: { value: Project }) => params.value.id,
      },
      minWidth: 100,
    },
    {
      colId: 'subprojectCount',
      headerName: 'Subproject Count',
      field: 'subprojectCount',
      hide: true,
      filter: 'agSetColumnFilter',
      filterParams: {
        values: async (
          params: SetFilterValuesFuncParams<Slide, { id: string; name: string }>
        ) => {
          if (!gridApi) {
            params.success([]);
            return;
          }

          const filterModel = gridApi?.current?.getFilterModel();
          const projectFilter = filterModel?.projectCount;

          if (!projectFilter?.values || projectFilter.values.length === 0) {
            params.success([]);
            return;
          }

          const selectedProjects = projectFilter.values;
          let allSubprojects: { id: string; name: string }[] = [];

          for (const projectId of selectedProjects) {
            const { data } = await apolloClient.query({
              query: GET_SUBPROJECTS_FILTER,
              variables: {
                projectId,
              },
            });

            if (data.project?.subProjects?.nodes) {
              allSubprojects = [
                ...allSubprojects,
                ...data.project.subProjects.nodes,
              ];
            }
          }

          params.success([...allSubprojects]);
        },
        defaultToNothingSelected: true,
        valueFormatter: (params: { value: { id: string; name: string } }) =>
          params.value?.name || '',
        keyCreator: (params: { value: { id: string; name: string } }) =>
          params.value?.id || '',
      },
      minWidth: 100,
    },
  ];
};

export const getAutoGroupColumnDef = (): ColDef => ({
  colId: 'name',
  headerName: 'Name',
  field: 'name',
  sortable: true,
  cellRenderer: 'agGroupCellRenderer',
  cellRendererParams: {
    innerRenderer: SlideThumbnailLinkCell,
  },
  filter: 'agTextColumnFilter',
  filterParams: {
    filterOptions: ['contains'],
    maxNumConditions: 1,
  },
  minWidth: 260,
  pinned: 'left',
});
