import { Flex } from '@aignostics/components';
import { pluralize } from '@aignostics/utils';
import React, { ReactElement } from 'react';

interface SelectionStatusPanelProps {
  selectedCount: number;
}

export const SelectionStatusPanel = ({
  selectedCount,
}: SelectionStatusPanelProps): ReactElement => {
  return (
    <Flex height="100%" align="center" gap="2">
      {selectedCount} {pluralize('slide', selectedCount || 0)} selected
    </Flex>
  );
};
