import { ApolloClient } from '@apollo/client';
import { SortModelItem } from 'ag-grid-enterprise';
import { Slide } from '../../../types';
import { GET_SLIDES_QUERY } from '../graphql/SlideLibrary.queries';
import { AppliedFilters } from '../types';
import { createVariables } from './createVariables';

export const fetchAllSelectedSlides = async (
  apolloClient: ApolloClient<object>,
  extractedFilters: AppliedFilters,
  sortModel: SortModelItem[],
  selection: {
    selectAll: boolean;
    toggledNodes: string[];
  },
  batchSize = 100
): Promise<Slide[]> => {
  let allSlides: Slide[] = [];

  const initialVariables = createVariables(
    extractedFilters,
    1,
    batchSize,
    sortModel,
    selection
  );

  const initialResponse = await apolloClient.query({
    query: GET_SLIDES_QUERY,
    variables: initialVariables,
    fetchPolicy: 'no-cache',
  });

  const totalElements =
    initialResponse.data?.slides?.pageInfo?.totalElements || 0;
  const totalPages = Math.ceil(totalElements / batchSize) || 1;

  allSlides = [...(initialResponse.data?.slides?.nodes || [])];

  // Fetch remaining pages in parallel batches if needed
  if (totalPages > 1) {
    const PARALLEL_REQUESTS = 3;
    const remainingPages = Array.from(
      { length: totalPages - 1 },
      (_, i) => i + 2
    );

    for (let i = 0; i < remainingPages.length; i += PARALLEL_REQUESTS) {
      const pageChunk = remainingPages.slice(i, i + PARALLEL_REQUESTS);

      const pageRequests = pageChunk.map((page) => {
        const pageVariables = createVariables(
          extractedFilters,
          page,
          batchSize,
          sortModel,
          selection
        );

        return apolloClient.query({
          query: GET_SLIDES_QUERY,
          variables: pageVariables,
          fetchPolicy: 'no-cache',
        });
      });

      const pageResults = await Promise.all(pageRequests);

      pageResults.forEach((result) => {
        const pageSlides = result.data?.slides?.nodes || [];
        allSlides = [...allSlides, ...pageSlides];
      });
    }
  }

  return allSlides;
};
