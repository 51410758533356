import { Flex, Link } from '@aignostics/components';
import { CustomCellRendererProps } from 'ag-grid-react';
import React, { ReactElement } from 'react';
import styled, { useTheme } from 'styled-components';
import { WsiThumbnail } from '../../../components';
import { Slide, SlideFluorescence } from '../../../types';
import { useSelectedOrganizationUuid } from '../../../utils';

export const CellWithThumbnail = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacings[8]}px;
  justify-content: flex-start;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 100%;
`;

export const ThumbnailContainer = styled.div`
  width: ${({ theme }) => theme.spacings[40]}px;
  height: ${({ theme }) => theme.spacings[40]}px;
  flex-shrink: 0;
`;

export interface GridContext {
  rasterTileServerUrl: string;
  getToken: () => Promise<string>;
}

const SlideThumbnailLinkCell = (
  props: CustomCellRendererProps<Slide | SlideFluorescence, string>
): ReactElement | null => {
  const theme = useTheme();
  if (!props.data) return null;
  const slide = props.data as Slide;
  const organizationUuid = useSelectedOrganizationUuid();
  const displayName = props.value;

  const isFluorescence = props.node.level > 0;

  if (isFluorescence) {
    return (
      <Flex align="center" style={{ paddingLeft: '30px' }}>
        <span>{displayName}</span>
      </Flex>
    );
  }

  return (
    <CellWithThumbnail>
      <ThumbnailContainer>
        <WsiThumbnail
          wsiId={(props.data as Slide).id}
          rasterTileServerUrl={props.context.rasterTileServerUrl}
          getToken={props.context.getToken}
          size="small"
        />
      </ThumbnailContainer>
      <Link
        href={`/${organizationUuid}/admin/wsi/${slide.id}`}
        target="_blank"
        style={{
          color: props.value
            ? theme.colors.primaryLight
            : theme.colors.linkBlue,
          paddingRight: theme.spacings[16],
        }}
      >
        {displayName}
      </Link>
    </CellWithThumbnail>
  );
};

export default SlideThumbnailLinkCell;
