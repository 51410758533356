import { getAgGridPaginationConfig } from '@aignostics/components';
import { ApolloClient, DocumentNode, useApolloClient } from '@apollo/client';
import {
  FilterModel,
  IServerSideDatasource,
  SortModelItem,
} from 'ag-grid-enterprise';
import { useMemo } from 'react';
import { Slide } from '../../../types';
import { GET_SLIDES_QUERY } from '../graphql/SlideLibrary.queries';
import { AppliedFilters } from '../types';
import {
  columnSorting,
  extractFiltersFromModel,
} from '../utils/extractFilters';

export const createServerSideDatasource = (
  client: ApolloClient<object>,
  queryDocument: DocumentNode
): IServerSideDatasource<Slide> => {
  return {
    getRows: async (params) => {
      try {
        const {
          startRow: maybeStartRow,
          endRow: maybeEndRow,
          sortModel,
          groupKeys,
          filterModel,
        } = params.request;

        const { page, pageSize } = getAgGridPaginationConfig(
          maybeStartRow,
          maybeEndRow
        );

        const appliedFilters = extractFiltersFromModel(
          filterModel as FilterModel
        );

        if (groupKeys && groupKeys.length > 0) {
          const slideId = groupKeys[0];

          let data;
          try {
            const response = await client.query({
              query: queryDocument,
              variables: {
                ...createVariables(appliedFilters, page, pageSize, sortModel),
                id: slideId,
              },
              fetchPolicy: 'cache-first',
            });
            data = response.data;
          } catch {
            params.fail();
            return;
          }

          const slides = data?.slides?.nodes ?? [];

          const childRows: Slide[] = slides
            .find((slide: Slide) => slide.id === slideId)
            .fluorescence.map((fluor: Slide) => ({
              id: fluor.id,
              name: fluor.name,
              staining: fluor.staining,
              objectivePower: '',
              disease: '',
              sampleType: '',
              preparationType: '',
              scanner: {
                id: '',
                vendor: '',
                uuid: '',
                model: '',
              },
              association: {
                id: '',
                uuid: '',
                name: '',
              },
              fluorescence: [],
              case: { id: '', sid: '', name: '' },
              batchName: '',
              blockName: '',
              patientId: '',
              section: '',
            }));

          params.success({
            rowData: childRows,
            rowCount: childRows.length,
          });
          return;
        }

        const variables = createVariables(
          appliedFilters,
          page,
          pageSize,
          sortModel
        );
        const { data, error } = await client.query({
          query: queryDocument,
          variables,
        });

        if (error) {
          throw error;
        }

        const rowData = data?.slides?.nodes ?? [];
        const totalElements =
          data?.slides?.pageInfo.totalElements ?? rowData.length;

        params.success({
          rowData,
          rowCount: totalElements,
        });
      } catch {
        params.fail();
      }
    },
  };
};

export function useServerSideSlideLibraryDatasource(): {
  datasource: IServerSideDatasource<Slide>;
} {
  const client = useApolloClient();

  const datasource = useMemo(
    () => createServerSideDatasource(client, GET_SLIDES_QUERY),
    [client]
  );

  return {
    datasource,
  };
}

export const createVariables = (
  appliedFilters: AppliedFilters,
  page: number,
  pageSize: number,
  sortModel: SortModelItem[]
): Record<string, string | string[] | number | number[]> => {
  const sortBy = columnSorting(sortModel?.[0]?.colId);
  const sortDirection = sortModel?.[0]?.sort || 'asc';

  const {
    associations,
    batches,
    diseases,
    objectivePowers,
    scanners,
    stainings,
    samplePreparation,
    sampleType,
    projects,
    subProjects,
    tissues,
    blockName,
    name,
    section,
    patientId,
    caseName,
    caseId,
    uuid,
  } = appliedFilters;

  return {
    associations,
    batches,
    diseases,
    objectivePowers,
    projects,
    sampleType,
    samplePreparation,
    scanners,
    stainings,
    tissues,
    subProjects,
    page,
    pageSize,
    sortBy,
    sortDirection,
    blockName,
    name,
    section,
    patientId,
    caseName,
    caseId,
    uuid,
  };
};
