import {
  Button,
  Input,
  Modal,
  SelectSingle,
  useSnackbarMutations,
  VisibleWithScope,
} from '@aignostics/components';
import { OrganizationRole, User } from '@aignostics/core';
import { Success } from '@aignostics/icons';
import { useApolloClient } from '@apollo/client';
import {
  GridApi,
  IServerSideSelectionState,
  SortModelItem,
} from 'ag-grid-enterprise';
import React, {
  FormEvent,
  ReactElement,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { Slide } from '../../../../types';
import { extractFiltersFromModel } from '../../utils/extractFilters';
import { fetchAllSelectedSlides } from '../../utils/fetchAllSelectedSlides';
import {
  $AssignSlidesForm,
  $ContentWrapper,
  $ModalSubTitle,
  $ModalTitle,
  $SuccessSubTitle,
  $SuccessTitle,
  $SuccessWrapper,
} from './AssignSlidesModal.styles';
import useAssignSlidesToProject from './useAssignSlidesToProject';

interface AssignSlidesModalProps {
  isOpen: boolean;
  selectionState: IServerSideSelectionState | null;
  gridApi: GridApi<Slide> | null;
  onCloseModal: () => void;
  role: OrganizationRole;
  currentUser: User;
}

export const AssignSlidesModal = ({
  isOpen,
  onCloseModal,
  selectionState,
  gridApi,
  role,
  currentUser,
}: AssignSlidesModalProps): ReactElement | null => {
  const navigate = useNavigate();
  const theme = useTheme();
  const apolloClient = useApolloClient();
  const [slideIds, setSlideIds] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const { addSnackbar } = useSnackbarMutations();

  const fetchSlides = useCallback(async () => {
    if (!selectionState || !gridApi || !isOpen) {
      return;
    }
    setIsLoading(true);
    try {
      const filterModel = gridApi.getFilterModel();
      const extractedFilters = extractFiltersFromModel(filterModel);

      const columnState = gridApi.getColumnState();
      const sortModel = columnState
        .filter((col) => col.sort)
        .map((col) => ({
          colId: col.colId,
          sort: col.sort,
        })) as SortModelItem[];

      const selection = {
        selectAll: selectionState.selectAll,
        toggledNodes: selectionState.toggledNodes || [],
      };

      const allSlides = await fetchAllSelectedSlides(
        apolloClient,
        extractedFilters,
        sortModel,
        selection
      );

      setSlideIds(allSlides.map((slide) => slide.id));
    } catch (err) {
      if (err instanceof Error) {
        addSnackbar({
          message: err.message || 'Error fetching slides',
          type: 'error',
        });
      }
    } finally {
      setIsLoading(false);
    }
  }, [selectionState, gridApi, isOpen, apolloClient, addSnackbar]);

  useEffect(() => {
    void fetchSlides();
  }, [selectionState, gridApi, isOpen, apolloClient, fetchSlides]);

  if (!selectionState || !gridApi) {
    return null;
  }

  const {
    assignToProject,
    changeProject,
    setProjectName,
    setSubprojectName,
    resetState,
    toggleCreateSubproject,
    toggleCreateProject,
    changeSubproject,
    subprojectsData: { subprojectsList, loadingSubprojects },
    projectsData: { projectsList, loadingProjects },
    state: { assignmentComplete, project, subproject, isSubmitting },
  } = useAssignSlidesToProject(slideIds, currentUser);

  const isValidProject =
    (project.isNew && project.name !== null) ||
    (!project.isNew && project.id !== null);

  const isValidSubproject =
    (subproject.isNew && subproject.name !== null) ||
    (!subproject.isNew && subproject.id !== null);

  const isAssignDisabled =
    isSubmitting ||
    !isValidProject ||
    !isValidSubproject ||
    isLoading ||
    slideIds.length === 0;

  const onClose = () => {
    resetState();
    onCloseModal();
  };

  const handleProjectNavigation = async () => {
    await navigate(
      `/${role.organization.uuid}/admin/projects/${project.id}/subproject/${subproject.id}/wsis?page=1`
    );
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    void assignToProject();
  };

  const renderProjectSelector = () => (
    <div>
      {project.isNew ? (
        <Input
          value={project.name ?? ''}
          id="project"
          label="Project Name"
          onChange={setProjectName}
          aria-label="New Project Name"
        />
      ) : (
        <SelectSingle
          id="name"
          label="Project Name"
          value={project.id}
          menuPosition="fixed"
          onChange={(data) => {
            changeProject(data?.value || null);
          }}
          options={loadingProjects ? [] : projectsList}
        />
      )}
      <VisibleWithScope role={role} scope="project:create" key="createProject">
        <Button variant="link" type="button" onClick={toggleCreateProject}>
          {project.isNew
            ? 'Assign to existing project'
            : 'Create a new project'}
        </Button>
      </VisibleWithScope>
    </div>
  );

  const renderSubprojectSelector = () => (
    <div>
      {subproject.isNew ? (
        <Input
          value={subproject.name ?? ''}
          id="Subproject"
          label="Subproject Name"
          onChange={setSubprojectName}
          aria-label="New Subproject Name"
        />
      ) : (
        <SelectSingle
          id="name"
          label="Subproject Name"
          value={subproject.id}
          disabled={!project}
          menuPosition="fixed"
          onChange={(d) => {
            changeSubproject(d?.value ?? null);
          }}
          options={loadingSubprojects ? [] : subprojectsList}
        />
      )}
      <VisibleWithScope
        role={role}
        scope="subproject:create"
        key="createSubproject"
      >
        <Button
          variant="link"
          type="button"
          disabled={project.isNew}
          onClick={toggleCreateSubproject}
        >
          {subproject.isNew
            ? 'Assign to existing sub project'
            : 'Create a new sub project'}
        </Button>
      </VisibleWithScope>
    </div>
  );

  if (assignmentComplete) {
    return (
      <Modal isVisible={isOpen} hasCloseButton onClose={onClose} size="small">
        <$SuccessWrapper>
          <Success
            size={68}
            style={{
              color: theme.colors.success,
              marginBottom: `${theme.spacings['24']}px`,
            }}
          />
          <$SuccessTitle>Slides successfully assigned</$SuccessTitle>
          <$SuccessSubTitle>What would you like to do next?</$SuccessSubTitle>

          <div>
            <Button type="button" onClick={handleProjectNavigation}>
              Go to Project
            </Button>
            <Button
              type="button"
              variant="primaryOutline"
              style={{ marginLeft: '16px' }}
              onClick={onClose}
            >
              Stay in Slide Library
            </Button>
          </div>
        </$SuccessWrapper>
      </Modal>
    );
  }

  return (
    <Modal isVisible={isOpen} hasCloseButton onClose={onClose} size="small">
      <$ContentWrapper>
        <$ModalTitle>Assign this Slides to project</$ModalTitle>
        <$ModalSubTitle>
          You need to choose or create the container project and then a
          Subproject to receive the slides.
        </$ModalSubTitle>

        {isLoading ? (
          <div>Loading slides data...</div>
        ) : (
          <$AssignSlidesForm onSubmit={handleSubmit}>
            {renderProjectSelector()}
            {renderSubprojectSelector()}
            <div>
              <Button type="submit" disabled={isAssignDisabled}>
                Assign {slideIds.length} slides
              </Button>
              <Button
                type="button"
                variant="primaryOutline"
                style={{ marginLeft: '16px' }}
                onClick={onClose}
              >
                Cancel
              </Button>
            </div>
          </$AssignSlidesForm>
        )}
      </$ContentWrapper>
    </Modal>
  );
};
