import { ApolloClient } from '@apollo/client';
import {
  GridApi,
  IServerSideSelectionState,
  SortModelItem,
} from 'ag-grid-enterprise';
import { Slide } from '../../../types';
import { downloadCSV } from '../../../utils';
import { SlideColumn } from '../SlideLibrary.component';
import { escapeCsvValue } from './escapeCsvValue';
import { extractFiltersFromModel } from './extractFilters';
import { fetchAllSelectedSlides } from './fetchAllSelectedSlides';

type Columns =
  | 'name'
  | 'staining'
  | 'uuid'
  | 'association'
  | 'batch'
  | 'tissue'
  | 'scanner'
  | 'disease'
  | 'objectivePower'
  | 'sampleType'
  | 'preparationType'
  | 'block'
  | 'caseID'
  | 'caseName'
  | 'patientId'
  | 'section';

export const downloadSlidesCsv = async (
  gridApi: GridApi<Slide>,
  apolloClient: ApolloClient<object>,
  selectionState: IServerSideSelectionState,
  columnsState: SlideColumn[]
): Promise<void> => {
  const filterModel = gridApi.getFilterModel();
  const extractedFilters = extractFiltersFromModel(filterModel);

  const columnState = gridApi.getColumnState();
  const sortModel = columnState
    .filter((col) => col.sort)
    .map((col) => ({
      colId: col.colId,
      sort: col.sort,
    })) as SortModelItem[];

  const selection = {
    selectAll: selectionState.selectAll,
    toggledNodes: selectionState.toggledNodes || [],
  };

  if (!selection.selectAll && selection.toggledNodes.length === 0) {
    downloadCSV([['No slides selected']]);
    return;
  }

  const allSlides = await fetchAllSelectedSlides(
    apolloClient,
    extractedFilters,
    sortModel,
    selection
  );

  const valueToCsv: Record<
    Columns,
    {
      label: string;
      renderCell: (row: Slide) => string | number | null;
    }
  > = {
    name: {
      label: 'Name',
      renderCell: (row) => row?.name ?? '',
    },
    staining: {
      label: 'Staining',
      renderCell: (row) => row?.staining ?? '',
    },
    uuid: {
      label: 'Id',
      renderCell: (row) => row?.id ?? '',
    },
    association: {
      label: 'Association',
      renderCell: (row) => row?.association?.name ?? '',
    },
    batch: {
      label: 'Batch',
      renderCell: (row) => row?.batchName ?? '',
    },
    tissue: {
      label: 'Localization',
      renderCell: (row) => row?.tissue ?? '',
    },
    scanner: {
      label: 'Scanner',
      renderCell: (row) => row?.scanner?.vendor ?? '',
    },
    disease: {
      label: 'Disease',
      renderCell: (row) => row?.disease ?? '',
    },
    objectivePower: {
      label: 'Objective Power',
      renderCell: (row) => row?.objectivePower ?? '',
    },
    sampleType: {
      label: 'Sample Type',
      renderCell: (row) => row?.sampleType ?? '',
    },
    preparationType: {
      label: 'Preparation Type',
      renderCell: (row) => row?.preparationType ?? '',
    },
    block: {
      label: 'Block',
      renderCell: (row) => row?.blockName ?? '',
    },
    caseID: {
      label: 'Case ID',
      renderCell: (row) => row?.case?.sid ?? '',
    },
    caseName: {
      label: 'Case Name',
      renderCell: (row) => row?.case?.name ?? '',
    },
    patientId: {
      label: 'Patient ID',
      renderCell: (row) => row?.patientId ?? '',
    },
    section: {
      label: 'Section',
      renderCell: (row) => row?.section ?? '',
    },
  };

  const filteredColumnsState = columnsState.filter((column) => {
    return column.checked && column.id !== 'ag-Grid-SelectionColumn';
  });

  const mappedColumns = filteredColumnsState.map((column) => {
    if (column.id === 'ag-Grid-AutoColumn') {
      return 'name' as Columns;
    }
    return column.id as unknown as Columns;
  });

  const headers = mappedColumns.map((columnId) => valueToCsv[columnId].label);

  const rows = allSlides.map((slide) => {
    return mappedColumns.map((columnId) => {
      const data = valueToCsv[columnId].renderCell(slide);
      return escapeCsvValue(data);
    });
  });

  downloadCSV([headers, ...rows]);
};
