import { SortModelItem } from 'ag-grid-enterprise';
import {
  ColumnFilterModel,
  FilterModel,
} from '../../AnnotationManagement/Categories/hooks/useServerSideDatasource';
import { AppliedFilters } from '../types';

// Column mapping for sorting and filtering
const COLUMN_MAP: Record<string, string> = {
  'association.name': 'association',
  'scanner.vendor': 'scanner',
  batchName: 'batch',
  id: 'uuid',
  'ag-Grid-AutoColumn': 'name',
  block: 'blockName',
};

export const columnSorting = (colName: string | undefined): string => {
  if (!colName) return 'name';
  return COLUMN_MAP[colName] ?? colName;
};

export const extractFilter = <T extends string | number>(
  model?: ColumnFilterModel
): T[] => {
  if (model?.filterType === 'set' && model.values && model.values.length > 0) {
    return model.values as T[];
  }
  return [] as T[];
};

export const extractTextFilter = (
  filterModel: FilterModel | undefined,
  fieldName: string
): string => {
  const fieldFilter = filterModel?.[fieldName];

  if (fieldFilter?.filterType === 'text' && fieldFilter.filter) {
    return fieldFilter.filter;
  }
  return '';
};

export const extractFiltersFromModel = (
  filterModel: FilterModel
): AppliedFilters => {
  return {
    associations: extractFilter(filterModel['association']),
    batches: extractFilter(filterModel['batch']),
    diseases: extractFilter(filterModel['disease']),
    objectivePowers: extractFilter(filterModel['objectivePower']),
    stainings: extractFilter(filterModel['staining']),
    tissues: extractFilter(filterModel['tissue']),
    scanners: extractFilter(filterModel['scanner']),
    sampleType: extractFilter(filterModel['sampleType']),
    samplePreparation: extractFilter(filterModel['preparationType']),
    blockName: extractTextFilter(filterModel, 'block'),
    caseName: extractTextFilter(filterModel, 'caseName'),
    caseId: extractTextFilter(filterModel, 'caseId'),
    name: extractTextFilter(filterModel, 'ag-Grid-AutoColumn'),
    uuid: extractTextFilter(filterModel, 'uuid'),
    patientId: extractTextFilter(filterModel, 'patientId'),
    section: extractTextFilter(filterModel, 'section'),
    projects: extractFilter(filterModel['projectCount']) || [],
    subProjects: extractFilter(filterModel['subprojectCount']) || [],
  };
};

export const createVariables = (
  appliedFilters: AppliedFilters,
  page: number,
  pageSize: number,
  sortModel: SortModelItem[]
): Record<string, string | string[] | number | number[]> => {
  const sortBy = columnSorting(sortModel?.[0]?.colId);
  const sortDirection = sortModel?.[0]?.sort || 'asc';

  return {
    ...appliedFilters,
    page,
    pageSize,
    sortBy,
    sortDirection,
  };
};
