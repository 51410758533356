import {
  IServerSideSelectionState,
  SelectionChangedEvent,
} from 'ag-grid-enterprise';
import { useCallback, useState } from 'react';

interface RowSelectionResult {
  selectionState: IServerSideSelectionState | null;
  handleSelectionChanged: (event: SelectionChangedEvent) => void;
}

export function useRowSelection(): RowSelectionResult {
  const [selectionState, setSelectionState] =
    useState<IServerSideSelectionState | null>(null);

  const handleSelectionChanged = useCallback((event: SelectionChangedEvent) => {
    const api = event.api;
    const currentSelectionState =
      api.getServerSideSelectionState() as IServerSideSelectionState;

    setSelectionState(currentSelectionState);
  }, []);

  return {
    selectionState,
    handleSelectionChanged,
  };
}
