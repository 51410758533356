import { AGGridTable, PageLayout } from '@aignostics/components';
import { OrganizationRole } from '@aignostics/core';
import { useApolloClient } from '@apollo/client';
import {
  GridApi,
  GridReadyEvent,
  RowSelectionOptions,
  SideBarDef,
} from 'ag-grid-enterprise';
import React, { ReactElement, useCallback, useMemo, useRef } from 'react';
import { Slide, UserWithRoles } from '../../types';
import { GridContext } from './components/SlideThumbnailLinkCell';
import { useRowSelection } from './hooks/useSelection';
import { useServerSideSlideLibraryDatasource } from './hooks/useSlideLibrary';
import { createStatusBar } from './utils/createStatusBar';
import {
  getAutoGroupColumnDef,
  getSlideLibraryColumnDefs,
} from './utils/getSlideLibraryColumnDef';

export type SlideColumn = {
  id: string;
  label: string;
  isFrozen?: boolean;
  checked?: boolean;
};

export const PAGE_SIZE = 40;

export const SlideLibrary = ({
  getToken,
  rasterTileServerUrl,
  role,
  currentUser,
}: {
  rasterTileServerUrl: string;
  getToken: () => Promise<string>;
  role: OrganizationRole;
  currentUser: UserWithRoles;
}): ReactElement => {
  const gridApiRef = useRef<GridApi<Slide> | null>(null);

  const { datasource } = useServerSideSlideLibraryDatasource();

  const apolloClient = useApolloClient();
  const columnDefs = useMemo(
    () => getSlideLibraryColumnDefs(apolloClient, gridApiRef),
    [apolloClient]
  );
  const autoGroupColumnDef = useMemo(() => {
    return getAutoGroupColumnDef();
  }, []);

  const rowSelection = useMemo<RowSelectionOptions | 'single' | 'multiple'>(
    () => ({
      mode: 'multiRow',
      selectAll: 'filtered',
      groupSelectsChildren: false,
      isRowSelectable: (node) => node.level === 0,
      hideDisabledCheckboxes: true,
    }),
    []
  );

  const { selectionState, handleSelectionChanged } = useRowSelection();

  const onGridReady = useCallback((params: GridReadyEvent<Slide>) => {
    gridApiRef.current = params.api;
  }, []);

  const gridContext = useMemo<GridContext>(
    () => ({
      rasterTileServerUrl,
      getToken,
    }),
    [rasterTileServerUrl, getToken]
  );

  const isServerSideGroup = useCallback(
    (dataItem: Slide) => (dataItem.fluorescence?.length || 0) > 0,
    []
  );

  const getServerSideGroupKey = useCallback(
    (dataItem: Slide) => dataItem.id,
    []
  );

  const sideBar = useMemo<SideBarDef>(
    () => ({
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
          toolPanelParams: {
            suppressRowGroups: true,
            suppressValues: true,
            suppressPivotMode: true,
          },
        },
      ],
      position: 'right',
    }),
    []
  );

  const statusBar = useMemo(
    () =>
      createStatusBar({
        selectionState,
        currentUser,
        role,
        columnDefs,
        gridApi: gridApiRef.current,
      }),
    [currentUser, role, selectionState, columnDefs]
  );

  return (
    <PageLayout title="Slide Library" fullWidth>
      <AGGridTable
        getRowId={({ data }) => data.id}
        rowSelection={rowSelection}
        selectionColumnDef={{
          pinned: 'left',
        }}
        columnDefs={columnDefs}
        serverSideDatasource={datasource}
        onSelectionChanged={handleSelectionChanged}
        onGridReady={onGridReady}
        treeData
        autoGroupColumnDef={autoGroupColumnDef}
        isServerSideGroup={isServerSideGroup}
        getServerSideGroupKey={getServerSideGroupKey}
        context={gridContext}
        rowModelType="serverSide"
        sideBar={sideBar}
        statusBar={statusBar}
      />
    </PageLayout>
  );
};
