import { AdvancedFilterModule, CellStyleModule, ClientSideRowModelApiModule, ClientSideRowModelModule, ColumnApiModule, ColumnMenuModule, ColumnsToolPanelModule, ContextMenuModule, CsvExportModule, CustomFilterModule, DateFilterModule, ExternalFilterModule, FiltersToolPanelModule, GridStateModule, LicenseManager, ModuleRegistry, MultiFilterModule, NumberEditorModule, NumberFilterModule, PaginationModule, QuickFilterModule, RowApiModule, ServerSideRowModelApiModule, ServerSideRowModelModule, SetFilterModule, StatusBarModule, TextEditorModule, TextFilterModule, themeQuartz, TooltipModule, } from 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import React, { forwardRef, useMemo } from 'react';
import { DefaultLoadingRenderer } from './DefaultLoadingRenderer';
import { useGridStatePersistence } from './hooks/useGridStatePersistence';
import { buildPaginationConfig, } from './utils/paginationUtils';
export const AG_GRID_LICENSE_KEY = 'Using_this_{AG_Grid}_Enterprise_key_{AG-074620}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Aignostics_GmbH}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{aignostics}_only_for_{9}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_working_on_{aignostics}_need_to_be_licensed___{aignostics}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{8_January_2026}____[v3]_[01]_MTc2NzgzMDQwMDAwMA==675428b68e91c883c23f04bab9ecd4f5';
ModuleRegistry.registerModules([
    AdvancedFilterModule,
    CellStyleModule,
    ClientSideRowModelApiModule,
    ClientSideRowModelModule,
    ColumnApiModule,
    ColumnMenuModule,
    ColumnsToolPanelModule,
    ContextMenuModule,
    CsvExportModule,
    CustomFilterModule,
    DateFilterModule,
    ExternalFilterModule,
    FiltersToolPanelModule,
    GridStateModule,
    MultiFilterModule,
    NumberEditorModule,
    NumberFilterModule,
    PaginationModule,
    QuickFilterModule,
    RowApiModule,
    ServerSideRowModelApiModule,
    ServerSideRowModelModule,
    SetFilterModule,
    StatusBarModule,
    TextEditorModule,
    TextFilterModule,
    TooltipModule,
]);
LicenseManager.setLicenseKey(AG_GRID_LICENSE_KEY);
export const defaultTableTheme = themeQuartz.withParams({
    accentColor: '#9B95EC',
    browserColorScheme: 'light',
    fontFamily: 'inherit',
    foregroundColor: '#483569',
    headerBackgroundColor: '#ECEBEF',
    headerFontSize: 14,
    headerFontWeight: 800,
});
/**
 * High-level Table component providing good defaults:
 * - Default theme
 * - Pagination enabled
 * - Default column definition
 * - Default loading state
 * - Easily override any of these via props
 */
function TableInner({ loadingCellRenderer, loadingCellRendererParams, persistParamsInUrl = true, initialGridState, defaultColDef: userDefaultColDef, rowModelType = 'serverSide', pagination = true, paginationPageSize, suppressCellFocus = true, initialState, ...restProps }, ref) {
    const { gridState, onStateUpdated } = useGridStatePersistence({
        enabled: persistParamsInUrl,
        initialState: initialGridState,
    });
    const mergedPaginationOptions = {
        enabled: pagination,
        pageSize: paginationPageSize ?? 20,
    };
    const paginationConfig = buildPaginationConfig(gridState, mergedPaginationOptions);
    const mergedDefaultColDef = useMemo(() => {
        return {
            flex: 1,
            resizable: true,
            suppressHeaderMenuButton: true,
            ...(userDefaultColDef ?? {}),
        };
    }, [userDefaultColDef]);
    const customLoadingCellRenderer = loadingCellRenderer ?? DefaultLoadingRenderer;
    const customLoadingCellRendererParams = loadingCellRendererParams ?? {
        loadingMessage: 'Loading...',
    };
    return (React.createElement(AgGridReact, { ref: ref, theme: defaultTableTheme, initialState: {
            ...initialState,
            ...gridState,
            partialColumnState: true,
        }, rowModelType: rowModelType, serverSideInitialRowCount: paginationConfig.initialRowCount, defaultColDef: mergedDefaultColDef, pagination: paginationConfig.enabled, paginationPageSize: paginationConfig.pageSize, paginationPageSizeSelector: [20, 50, 100], cacheBlockSize: paginationConfig.pageSize, loadingCellRenderer: customLoadingCellRenderer, loadingCellRendererParams: customLoadingCellRendererParams, onStateUpdated: onStateUpdated, suppressCellFocus: suppressCellFocus, ...restProps }));
}
// this allows us to provide a ref to ts generic component
export const Table = forwardRef(TableInner);
