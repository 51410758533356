import {
  Button,
  Flex,
  useSnackbarMutations,
  VisibleWithScope,
} from '@aignostics/components';
import { OrganizationRole } from '@aignostics/core';
import { useApolloClient } from '@apollo/client';
import { GridApi, IServerSideSelectionState } from 'ag-grid-enterprise';
import React, { ReactElement, useState } from 'react';
import { Slide, UserWithRoles } from '../../../types';
import { SlideColumn } from '../SlideLibrary.component';
import { downloadSlidesCsv } from '../utils/downloadSlidesCsv';
import { AssignSlidesModal } from './AssignSlidesModal/AssignSlidesModal.component';

export const SelectedRowsActions = ({
  role,
  currentUser,
  columnsState,
  gridApi,
  selectionState,
}: {
  role: OrganizationRole;
  currentUser: UserWithRoles;
  columnsState: SlideColumn[];
  gridApi: GridApi<Slide> | null;
  selectionState: IServerSideSelectionState | null;
}): ReactElement => {
  const apolloClient = useApolloClient();
  const [isDownloading, setIsDownloading] = useState(false);
  const { addSnackbar } = useSnackbarMutations();

  const onDownloadCsv = async () => {
    if (gridApi && selectionState) {
      try {
        setIsDownloading(true);
        await downloadSlidesCsv(
          gridApi,
          apolloClient,
          selectionState,
          columnsState
        );
        setIsDownloading(false);
      } catch (error) {
        if (error instanceof Error) {
          addSnackbar({
            message: error.message || 'Error downloading CSV',
            type: 'error',
          });
        }
        setIsDownloading(false);
      }
    }
  };

  const [assignSlidesOpen, setAssignSlidesOpen] = useState<boolean>(false);

  return (
    <Flex justify="center" gap="2" style={{ margin: '8px' }}>
      <Button
        icon="Download"
        small
        variant="primaryOutline"
        onClick={onDownloadCsv}
        disabled={!gridApi || !selectionState || isDownloading}
      >
        {isDownloading ? 'Preparing CSV...' : 'Selected slides CSV'}
      </Button>
      <VisibleWithScope scope="wsi:readAll" role={role}>
        <Button
          variant="primary"
          small
          onClick={() => {
            setAssignSlidesOpen(true);
          }}
        >
          Assign To Project
        </Button>
      </VisibleWithScope>
      <AssignSlidesModal
        isOpen={assignSlidesOpen}
        gridApi={gridApi}
        selectionState={selectionState}
        onCloseModal={() => {
          setAssignSlidesOpen(false);
        }}
        role={role}
        currentUser={currentUser}
      />
    </Flex>
  );
};
