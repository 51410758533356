import { OrganizationRole } from '@aignostics/core';
import {
  ColDef,
  GridApi,
  IServerSideSelectionState,
  StatusPanelDef,
} from 'ag-grid-enterprise';
import React from 'react';
import { Slide, UserWithRoles } from '../../../types';
import { SelectedRowsActions } from '../components/SelectedRowsActions.component';
import { SelectionStatusPanel } from '../components/SelectionStatusPanel';

interface StatusBarProps {
  selectionState: IServerSideSelectionState | null;
  currentUser: UserWithRoles;
  role: OrganizationRole;
  columnDefs: ColDef<Slide>[];
  gridApi: GridApi<Slide> | null;
}

export const createStatusBar = ({
  selectionState,
  currentUser,
  role,
  columnDefs,
  gridApi,
}: StatusBarProps): {
  statusPanels: StatusPanelDef[];
} => {
  if (
    !selectionState ||
    (selectionState.toggledNodes.length === 0 && !selectionState.selectAll)
  ) {
    return {
      statusPanels: [],
    };
  }

  return {
    statusPanels: [
      {
        statusPanel: () => {
          const totalElements = gridApi?.paginationGetRowCount() || 0;

          let selectedCount = selectionState.toggledNodes.length;
          if (selectionState?.selectAll) {
            selectedCount =
              selectionState.toggledNodes.length === 0
                ? totalElements
                : totalElements - selectionState.toggledNodes.length;
          }

          return <SelectionStatusPanel selectedCount={selectedCount} />;
        },
        align: 'left',
      },
      {
        statusPanel: () => {
          const columns =
            gridApi?.getColumnState().map((col) => {
              const { colId, hide } = col;
              const column = columnDefs.find(
                (column) => column.colId === colId
              );
              return {
                id: colId,
                label: column?.headerName || colId,
                checked: !hide,
              };
            }) || [];

          return (
            <SelectedRowsActions
              currentUser={currentUser}
              role={role}
              columnsState={columns}
              gridApi={gridApi}
              selectionState={selectionState}
            />
          );
        },
        align: 'right',
      },
    ],
  };
};
